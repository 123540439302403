@font-face {
    font-family: "Ranga";
    src: url('./assets/fonts/Ranga-Regular.ttf');
}
@font-face {
    font-family: "Docis";
    src: url('./assets/fonts/Dosis-VariableFont_wght.ttf');
}
.App {
    text-align: center;
    width: 100%;
    min-width: 360px;
}
.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: white;
    flex-wrap: nowrap;
    padding-top: .8rem;
    padding-bottom: .4rem;

}
.mainTagLineA {
    font-family: 'Ranga', cursive;
    font-size: clamp(1rem, 0.4737rem + 2.807vw, 3rem);
    opacity: 0.84;
}
.accentText {
    font-weight: 800;
    opacity: 90;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
}
.center-text {
    text-align: center;
}
.underline {
    text-decoration: underline;
    text-decoration-thickness: 0.15rem;
}
.outlet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    padding: 10px;
    scroll-snap-type: y mandatory;
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
}
.featureContainer {
    scroll-snap-align: center;
}
.featureRow {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
    scroll-snap-align: start;
}
.featureRow:nth-of-type(2n) {
    flex-direction: row-reverse;
}
.featureColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.featureImageContainer {
    margin: 0 auto;
    max-height: 512px;
    max-width: 512px;
    position: relative;
    padding-bottom: 3rem;
}
.featureTextContainer {
    padding-bottom: 3.5rem;
    padding-top: 1rem;
    margin: 2rem;
}
.featureImage {
    object-fit: scale-down;
    max-width: 100%;
    height: auto;
}
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    padding-top: 2rem;
    padding: 1rem;
}
.nav-btn:hover {
    opacity: 0.7;
}
.header-text {
    font-family: 'Dosis', sans-serif;
    font-size: clamp(1.2rem, 1.35rem + 0.85vw, 2.35rem);
    font-weight: 600;
    flex-wrap: nowrap;
    flex-shrink: 1;
}
.header-static {
    font-family: 'Dosis', sans-serif;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    /* font-size: clamp(1.35rem, 1.2368rem + 1.4035vw, 2.5rem); */
    font-size: clamp(1.2rem, 1.12rem + 0.85vw, 2.35rem);
    max-height: 2.6rem;
    flex-shrink: 1;
    padding-top:.3rem;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.purple-text {
    color: purple;
}
.snap {
    scroll-snap-align: start;
}
.snap-center {
    scroll-snap-align: center;
}
.snap-end {
    scroll-snap-align: end;
}
.est-btn {
    padding-top: 2rem;
}
.gallery-photo {
    max-height: 100vh;
    object-fit: scale-down;
    scroll-snap-align: start;
}
.full-width {
    max-width: 80%;
}
.pad-bot {
    padding-bottom: 1rem;
}
